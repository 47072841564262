import styled from 'styled-components';
import {query} from '@theme/fn';
import {color, font} from '@design/templateFns';
import Block from '@components/Layout/Block';
import AspectBox from '@components/Layout/AspectBox';
import ArcImg from '@components/ArcImg';
import {ResolvedImageData} from '@data/types';
import {ProseContent} from '@data/types';
import {RenderProseContent} from '@components/PortableText';
import React from 'react';

export interface InteriorHeroProps {
  header?: string;
  caption?: string;
  headerPadding?: boolean;
  alt_text?: string;
  imgs?: {
    desktop: ResolvedImageData;
    mobile: ResolvedImageData;
  };
  narrow?: true;
  videoSrc?: string;
  bodyContent?: ProseContent;
  bottomHeader?: string;
  koppersFullBleed?: true;
}

/*** STYLING ***/
const Container = styled.div`
  width: 100%;

  @media (${query.atLeast('tablet')}) {
    padding-top: 0px;
  }
`;

const Header = styled.h1`
  ${font('headline-xxl')};
  margin-top: 26px;
  margin-bottom: 26px;
  text-align: center;
  padding: 0;
  @media (${query.atLeast('tablet')}) {
    margin-bottom: 36px;
  }

  @media (${query.atLeast('desktop')}) {
    font-size: 64px;
    margin-top: 72px;
  }
`;

const Caption = styled.p`
  ${font('body-lg-regular')};
  text-align: center;
  margin: 0 auto 40px;
  padding: 0 20px;
  max-width: 620px;

  @media (${query.atLeast('desktop')}) {
    margin-bottom: 72px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
`;

const BottomSection = styled.div`
  background-color: ${color('cta')};
  width: 100%;
`;

const BottomHeader = styled.h2`
  ${font('headline-xxl')};
  color: ${color('bg')};
  text-align: center;
  margin: 50px auto 16px;
  padding: 0 20px;

  @media (${query.atLeast('desktop')}) {
    max-width: 860px;
    margin: 100px auto 0;
  }
`;

const BottomBlock = styled(Block)`
  ${font('body-lg-medium')};
  color: ${color('bg')};
  text-align: center;
  margin: 0 auto 50px;
  padding: 20px;
  > div {
    margin: 0;
  }
  @media (${query.atLeast('desktop')}) {
    max-width: 883px;
    margin: 0 auto 64px;
    padding: 36px 46px;
  }
`;

export const InteriorHero = ({
  header,
  caption,
  alt_text,
  imgs,
  videoSrc,
  headerPadding = false,
  narrow,
  bottomHeader,
  bodyContent,
  koppersFullBleed,
}: InteriorHeroProps): JSX.Element => {
  let layout = {
    squish: (narrow && !koppersFullBleed) || undefined,
    full: !!koppersFullBleed || undefined,
  };
  return (
    <React.Fragment>
      <Block {...{...layout}}>
        <Container>
          {(header || caption) && (
            <>
              {header && <Header>{header}</Header>}
              <div>{caption && <Caption>{caption}</Caption>}</div>
            </>
          )}
          {((imgs && imgs.desktop) || videoSrc) && (
            <AspectBox ratio={[1.4, 1.9, 2.12]}>
              {imgs?.desktop && (
                <ArcImg
                  alt={alt_text}
                  imageData={imgs.desktop}
                  sizes={['100vw', null, null, '1440px']}
                />
              )}
              {videoSrc && (
                <Video
                  {...{
                    muted: true,
                    src: videoSrc,
                    autoPlay: true,
                    loop: true,
                  }}
                />
              )}
            </AspectBox>
          )}
        </Container>
      </Block>
      <BottomSection>
        {bottomHeader && (
          <div>
            <BottomHeader>{bottomHeader}</BottomHeader>
          </div>
        )}
        {bodyContent && (
          <BottomBlock squish narrow>
            <RenderProseContent content={bodyContent} />
          </BottomBlock>
        )}
      </BottomSection>
    </React.Fragment>
  );
};
