import styled from 'styled-components';
import {color, space, query} from '@theme/fn';
import {font} from '@design/templateFns';
import AspectBox from '@components/Layout/AspectBox';
import {mq} from '@theme/query';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0;
  grid-column-gap: 0;

  @media screen and (${query.atLeast('tablet')}) {
    grid-template-columns: ${({reverse}) =>
      reverse ? 'minmax(280px, 450px) 1fr' : '1fr minmax(280px, 450px)'};
    grid-column-gap: ${space('lg')};
  }

  @media screen and (${query.atLeast('desktop')}) {
    grid-template-columns: ${({reverse}) =>
      reverse ? 'minmax(453px, 50%) 1fr' : '1fr minmax(453px, 50%)'};
    grid-column-gap: ${space('x6')};
  }
`;

export const ContentContainer = styled.div`
  margin-top: ${space('xl')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-row: 2;
  color: ${({swatch}) =>
    swatch === 'green' ? color('background') : color('midnight')};
  @media screen and (${query.atLeast('tablet')}) {
    margin-top: 0;
    grid-row: ${({reverse}) => (reverse ? 1 : 'unset')};
  }
`;

export const Header = styled.h1`
  ${font('headline-xxl')};
  margin: 0 0 28px;
  max-width: 743px;
  padding: 0;
`;

export const Eyebrow = styled.h3`
  text-transform: uppercase;
  ${font('headline-xxs')};
  letter-spacing: 0.12em;
  padding-bottom: 15px;
  margin-top: 0;
  @media screen and (${query.atLeast('tablet')}) {
    font-size: 12px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 14px;
  }
`;

export const Text = styled.div`
  margin-bottom: 52px;
  p {
    margin: 0;
    ${font('body-lg-regular')};
  }
`;

export const Scrunch = styled.div``;

export const ImageBox = styled(AspectBox)``;
