import {Button} from '@components/Button/';
import Block from '@components/Layout/Block';
import type {BasicText, ResolvedImageData} from '@data/types';
import {RenderBasicText} from '@components/PortableText';
import {
  ContentContainer,
  Eyebrow,
  GridContainer,
  Header,
  ImageBox,
  Text,
} from './styles';
import Scrunch from '@components/Layout/Scrunch';
import ArcImg from '@components/ArcImg';

export interface TextAndImageBlockProps {
  swatch?: string; // this is the component variant, either default or green
  eyebrow?: string;
  header?: string;
  img: ResolvedImageData;
  alt_text?: string;
  bodyText: BasicText | string;
  btnText?: string;
  btnUrl?: string;
  reverse?: boolean;
  portrait?: boolean;
}

const TextAndImageBlock = ({
  swatch,
  header,
  eyebrow,
  img,
  alt_text,
  bodyText,
  btnText,
  btnUrl,
  reverse,
  portrait,
}: TextAndImageBlockProps): JSX.Element => {
  return (
    <Block background={swatch == 'green' ? 'secondaryBg' : 'bg'}>
      <GridContainer reverse={reverse}>
        <Scrunch wCss={portrait ? [null, '66%'] : [null, '84%']}>
          <ImageBox ratio={portrait ? 3 / 4 : 4 / 3}>
            <ArcImg
              alt={alt_text}
              imageData={img}
              sizes={['50vw', null, null, '743px']}
            />
          </ImageBox>
        </Scrunch>
        <ContentContainer reverse={reverse} swatch={swatch}>
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          {header && <Header>{header}</Header>}
          {typeof bodyText === 'object' ? (
            <Text>
              <RenderBasicText asFragment content={bodyText} />
            </Text>
          ) : (
            <Text>{bodyText}</Text>
          )}
          {btnUrl && btnText && (
            <Button
              arrowColor={swatch == 'green' ? 'black' : 'white'}
              arrow={true}
              variant={swatch == 'green' ? 'primaryOrange' : 'primaryBlue'}
              url={btnUrl}
              label={btnText}
            />
          )}
        </ContentContainer>
      </GridContainer>
    </Block>
  );
};

export default TextAndImageBlock;
