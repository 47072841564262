import styled from 'styled-components';
import {SimpleComponent} from '@util/types';
import {minmax} from '@util/num';
import {mq} from '@design/query';

export interface ScrunchProps {
  w?: number;
  h?: number;
  wCss?: string[];
  hCss?: string[];
}

const maxesFn = ({w, h}: ScrunchProps) => ({
  maxWidth: w != null ? `${minmax(0, 1, w) * 100}%` : null,
  maxHeight: h != null ? `${minmax(0, 1, h) * 100}%` : null,
});

const maxesCssFn = ({wCss, hCss}: ScrunchProps) =>
  mq({
    maxWidth: wCss || [],
    maxHeight: hCss || [],
  });

const InnerScrunch = styled.div`
  position: relative;
  width: 100%;
  ${maxesFn}
  ${maxesCssFn}
`;

const OuterScrunch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Scrunch: SimpleComponent<ScrunchProps> = (props) => {
  return (
    <OuterScrunch>
      <InnerScrunch {...props}>{props.children}</InnerScrunch>
    </OuterScrunch>
  );
};

export default Scrunch;
