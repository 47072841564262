import styled, {css} from 'styled-components';
import {CircleArrow} from '../Arrow/index';
import {DoubleQuote} from './CardIcons';
import {RightArrowNoLine} from '../Arrow/index';
import {query, fontFamily, fontWeight} from '@theme/fn';

import {color, font} from '@design/templateFns';
import AspectBox from '@components/Layout/AspectBox';
import {default as NextImage} from 'next/image';
import type {BasicText, ResolvedImageData} from '@data/types';
import Link from '@components/Link';
import React from 'react';
import ArcImg from '@components/ArcImg';

export interface CarouselCardProps {
  cardTitle: string;
  cardDescription?: string;
  cardImage: ResolvedImageData;
  ctaLink?: string;
  imageAspectRatio?: number | number[];
}

export interface StatCardProps {
  backgroundColor: string;
  baselineText: string;
  statisticText: string;
  iconPath: string;
}

export interface TestimonialCardProps {
  testimonialText: string;
  patientName: string;
  patientSecondLine?: string;
  patientPhotoPath?: string;
  cardFullWidth: boolean;
  ctaLink: string;
  ctaText: string;
  backgroundColor: string;
}

export interface ArticleCardProps {
  image: ResolvedImageData;
  category: string;
  headline: string;
  date?: string;
  description: BasicText | string | JSX.Element;
  cardFullWidth: boolean;
  alt_text?: string;
  url: string;
  imageAspectRatio?: number | number[];
}

const QuoteDiv = styled.div`
  width: 10px;
  display: inline-block;

  @media screen and (${query.atLeast('desktop')}) {
    width: 14px;
  }
`;

const CardContainer = styled.div`
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.fonts.body};
  width: ${(props) => (props.fullWidth ? '100%' : '254px')};
  height: 333px;
  padding: 26px 32px;

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  box-shadow: ${(props) => props.boxShadow};

  @media screen and (${query.atLeast('tablet')}) {
    width: 280px;
    height: 332px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    width: 408px;
    height: 474px;
    padding: 36px 44px;
  }
`;
const CarouselCardContainer = styled.div`
  position: relative;
  border-radius: 15px;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.fonts.body};
  width: ${(props) => (props.fullWidth ? '100%' : '254px')};

  padding: 0;

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  box-shadow: ${(props) => props.boxShadow};
  img {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  width: 265px;
  height: 460px;

  @media screen and (${query.atLeast('desktop')}) {
    width: 288px;
    height: 485px;
  }

  a {
    color: #192630;
  }
  @media screen and (max-width: 760px) {
    padding-bottom: 20px;
  }
`;

const IconContainer = styled.div`
  width: 87px;
  height: 87px;
  top: 32px;
  left: 32px;
  position: absolute;
  background-size: cover;

  background-image: ${(props) =>
    'url({0})'.replace('{0}', props.backgroundImage)};

  @media screen and (${query.atLeast('tablet')}) {
    width: 56px;
    height: 56px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    width: 108px;
    height: 108px;
    top: 46px;
    left: 46px;
  }
`;

const BaselineContainer = styled.div`
  position: absolute;
  left: 32px;
  right: 32px;
  bottom: 25px;

  @media screen and (${query.atLeast('desktop')}) {
    left: 46px;
    right: 46px;
    bottom: 46px;
  }
`;

const BaselineText = styled.div`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 14px;
  line-height: 20px;

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const StatisticText = styled.div`
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 700;
  font-size: 56px;

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 80px;
  }
`;

const TestimonialText = styled.div`
  ${font('headline-xs')};
  color: ${color('primary')};
  font-size: 20px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 24px;

  @media screen and (${query.atLeast('tablet')}) {
    font-size: 22px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 48px;
  }
`;

const PatientLine = styled.div`
  display: flex;
  align-items: center;
`;

const PatientPhoto = styled.div`
  border-radius: 23px;
  background-color: #ddd;
  width: 50px;
  height: 50px;
  display: none;
  background-size: cover;

  background-image: ${(props) =>
    'url({0})'.replace('{0}', props.backgroundImage)};

  @media screen and (${query.atLeast('desktop')}) {
    width: 70px;
    height: 70px;
    min-width: 70px;
    display: inline-block;
  }
`;

const PatientName = styled.div`
  ${font('headline-xxs')};
  margin-top: -5px;
  color: #192630;

  p {
    font-weight: 500;
    font-size: 16px;
  }

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 18px;
    margin-left: 12px;
  }
`;

const MoreCTALink = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  position: absolute;
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 14px;
  bottom: 18px;
  left: 32px;

  @media screen and (${query.atLeast('desktop')}) {
    font-size: 18px;
    bottom: 37px;
    left: 49px;
  }
`;

const CTAText = styled.div`
  margin-right: 8px;
`;

export const StatCard = ({
  backgroundColor,
  baselineText,
  statisticText,
  iconPath,
}: StatCardProps): JSX.Element => {
  return (
    <CardContainer
      backgroundColor={backgroundColor}
      color={'#fff'}
      boxShadow={'0px 8px 15px 0px rgba(0, 0, 0, 0.1)'}
    >
      <IconContainer backgroundImage={iconPath}></IconContainer>
      <BaselineContainer>
        <StatisticText>{statisticText}</StatisticText>
        <BaselineText>{baselineText}</BaselineText>
      </BaselineContainer>
    </CardContainer>
  );
};

export const TestimonialCard = ({
  testimonialText,
  patientName,
  patientSecondLine,
  patientPhotoPath,
  cardFullWidth,
  ctaLink,
  ctaText,
  backgroundColor = '#fff',
}: TestimonialCardProps): JSX.Element => {
  return (
    <CardContainer
      backgroundColor={backgroundColor}
      color={'#484848'}
      boxShadow={'0px 8px 15px 0px rgba(0, 0, 0, 0.1)'}
      fullWidth={cardFullWidth}
    >
      <QuoteDiv>
        <DoubleQuote />
      </QuoteDiv>
      <TestimonialText>{testimonialText}</TestimonialText>
      <PatientLine>
        {patientPhotoPath != null && patientPhotoPath != '' && (
          <PatientPhoto backgroundImage={patientPhotoPath}></PatientPhoto>
        )}
        <PatientName>
          {patientName}
          {patientSecondLine && <p>{patientSecondLine}</p>}
        </PatientName>
      </PatientLine>
      {ctaLink && ctaText && (
        <Link href={ctaLink}>
          <MoreCTALink>
            <CTAText>{ctaText}</CTAText>
            <CircleArrow />
          </MoreCTALink>
        </Link>
      )}
    </CardContainer>
  );
};

export const CarouselCard = ({
  cardTitle,
  cardDescription,
  cardImage,
  ctaLink,
  imageAspectRatio = cardDescription ? 0.85 : 0.7,
}: CarouselCardProps): JSX.Element => {
  return (
    <CarouselCardContainer
      backgroundColor={'#FFF'}
      color={'#484848'}
      boxShadow={'0px 8px 15px 0px rgba(0, 0, 0, 0.1)'}
      fullWidth={false}
    >
      <ImgWrapper ratio={imageAspectRatio}>
        {cardImage && (
          <ArcImg
            alt={cardTitle}
            imageData={cardImage}
            sizes={['50vw', null, null, '743px']}
            data-aspect={imageAspectRatio}
          />
        )}
      </ImgWrapper>
      {ctaLink && (
        <Link href={ctaLink}>
          <StyledLink>
            <CarouselCardHeader
              className={cardTitle.length > 24 ? 'long-text' : ''}
            >
              {cardTitle}&nbsp;&nbsp;
              <RightArrowNoLine stroke={'#1B76B0'} size={8} />
            </CarouselCardHeader>
          </StyledLink>
        </Link>
      )}
      {!ctaLink && <CarouselCardHeader>{cardTitle}</CarouselCardHeader>}
      {cardDescription && (
        <CarouselCardDescription>{cardDescription}</CarouselCardDescription>
      )}
    </CarouselCardContainer>
  );
};

const ImgWrapper = styled(AspectBox)`
  margin-bottom: 13px;
`;

export const ArticleCard = ({
  image,
  category,
  headline,
  date,
  alt_text,
  description,
  cardFullWidth,
  url,
  imageAspectRatio = 1.618,
}: ArticleCardProps): JSX.Element => {
  const renderDateString = (dateIsoString: string): JSX.Element => {
    const dateObj = dateIsoString
      ? new Date(
          dateIsoString.match(/T.+/)
            ? dateIsoString
            : `${dateIsoString}T05:00:00.000Z`, // EST Eastern Standard Time
        ) // This regex ensures the correct date renders. Also won't break Safari.
      : new Date();
    const year = dateObj.getFullYear();
    const month = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(
      dateObj,
    );
    const day = dateObj.getDate();
    return (
      <span>
        {month} {day}, {year}{' '}
      </span>
    );
  };
  return (
    <StyledContainer fullWidth={cardFullWidth}>
      {image && (
        <ImgWrapper ratio={imageAspectRatio}>
          <ArcImg
            alt={alt_text}
            imageData={image}
            sizes={['50vw', null, null, '743px']}
            data-aspect={imageAspectRatio}
          />
        </ImgWrapper>
      )}
      <StyledBox>
        <Link href={url}>
          <StyledLink>
            <StyledHeader
              fontWeight="bold"
              color="midnight"
              p={['5px 0', null, null, '7px 0']}
              m="0"
            >
              {headline}
            </StyledHeader>
          </StyledLink>
        </Link>
        {date && <StyledDate>{renderDateString(date)}</StyledDate>}
        <StyledDescription>{description}</StyledDescription>
      </StyledBox>
    </StyledContainer>
  );
};

export default StatCard;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.fullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: 256px;
          @media (${query.atLeast('tablet')}) {
            width: 288px;
          }
          @media (${query.atLeast('max')}) {
            width: 408px;
          } ;
        `}
`;

const StyledBox = styled.div``;

const StyledLink = styled.a`
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  .long-text {
    font-size: 17px;
  }
`;

const CarouselCardHeader = styled.h2`
  ${font('headline-xs')};
  color: ${color('primary')};
  margin: 0;
  padding: 10px 20px 14px;
  @media screen and (max-width: 760px) {
    font-size: 14px;
    padding-bottom: 3px;
    svg {
      width: 6px;
    }
  }
`;

const CarouselCardDescription = styled.p`
  ${font('body-sm-regular')};
  padding: 0 20px;
  color: ${color('fg')};
  @media screen and (max-width: 760px) {
    font-size: 14px;
  }
`;

const StyledHeader = styled.h2`
  ${font('headline-xs')};
  color: ${color('primary')};
  margin: 0;
  padding: 15px 0 8px;
`;

const StyledDescription = styled.div`
  ${font('body-md-regular')};
  line-height: 23.04px;
  color: ${color('fg')};
  margin-top: 0;
`;

const StyledDate = styled.p`
  display: inherit;
  ${font('headline-xxxs')};
  color: ${color('cta')};
  padding: 10px 0 16px;
  margin: 0;
  text-transform: uppercase;
  span {
    font-weight: 700;
  }
`;
